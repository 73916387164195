import * as React from "react";
import { StaticImage } from "gatsby-plugin-image";
import Layout from "../../components/global/Layout";
import SectionParagraph from "../../components/ui/SectionParagraph";
import SectionTitle from "../../components/ui/SectionTitle";

const OdpadyKatalizatorowPage = () => {
  return (
    <Layout
      titleTemplate="%s"
      pageTitle="Skup odpadów katalizatorów - filtry cząstek stałych, monolit"
      pageDescription="Nasz zakład zajmuje się skupem odpadów katalizatorów do których zaliczamy katalizator, filtr cząstek stałych i monolit."
    >
      <section className="container mx-auto px-5 py-16">
        <div className="flex flex-row gap-6 justify-between items-center flex-wrap lg:flex-nowrap space-x-8">
          <div className="md:w-1/2">
            <StaticImage
              src="../../images/services/katalizatory.jpg"
              alt="Odpady katalizatorów"
              width={800}
              height={600}
            />
          </div>
          <div className="md:w-1/2">
            <SectionTitle size="small">Odpady katalizatorów</SectionTitle>
            <SectionParagraph className="mb-5">Skupujemy:</SectionParagraph>
            <ul className="list-disc text-xl text-gray-700 pl-5 mb-5">
              <li>katalizatory</li>
              <li>filtry cząstek stałych</li>
              <li>monolit</li>
            </ul>
            <SectionParagraph className="mb-5">
              Badanie spektrometrem na miejscu.
            </SectionParagraph>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default OdpadyKatalizatorowPage;
