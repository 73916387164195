import React from "react";

const SectionParagraph = ({ children, className, color = "dark" }) => {
  return (
    <p
      className={`text-xl ${className} ${
        color === "dark" ? "text-gray-700" : "text-gray-200"
      }`}
    >
      {children}
    </p>
  );
};

export default SectionParagraph;
