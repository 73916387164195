import React from "react";

const SectionTitle = ({
  children,
  size = "big",
  color = "dark",
  align = "left",
}) => {
  return (
    <h2
      className={`font-semibold font-titles  mb-6
      ${size === "big" ? "text-3xl md:text-5xl" : "text-2xl md:text-4xl"}
      ${color === "dark" ? "text-section" : "text-gray-200"}
      ${align === "left" ? "text-left" : "text-center"}`}
    >
      {children}
    </h2>
  );
};

export default SectionTitle;
