import React from "react";
import Seo from "./Seo";
import Header from "./Header";
import Footer from "./Footer";

export default function Layout({
  children,
  pageTitle,
  pageDescription,
  titleTemplate,
}) {
  return (
    <React.Fragment>
      <Seo
        title={pageTitle}
        description={pageDescription}
        titleTemplate={titleTemplate}
      />
      <Header />
      <main>{children}</main>
      <Footer />
    </React.Fragment>
  );
}
